<template>
  <div>
    <button
    class="flex flex-row border-opacity-0 hover:text-neutral-800"
    v-on:click="toggleOrdering()">
      {{title}}
      <span v-if="this.orderIcon === 'descending'"><Icon name="chevron-down" class="h-4 w-4" /></span>
      <span v-else-if="this.orderIcon === 'ascending'"><Icon name="chevron-up" class="h-4 w-4" /></span>
    </button>
  </div>
</template>
<script>
import Icon from '@/components/icon.vue'
import { mapState } from 'vuex'

export default {
  name: 'OrderColumn',
  computed: {
    ...mapState({
      projectListFilters: state => state.projects.projectListFilters
    }),
    /*
    Sets the ascending/descending icon if we are ordering by `this.field`
    */
    orderIcon: function () {
      let returnVal = ''
      if (this.projectListFilters.orderChoice === this.field) {
        returnVal = 'ascending'
      } else if (this.projectListFilters.orderChoice === `-${this.field}`) {
        returnVal = 'descending'
      }
      return returnVal
    }
  },
  components: {
    Icon
  },
  methods: {
    /*
    Toggles ordering field saved to `orderChoice` store state
    */
    toggleOrdering: function () {
      if (this.projectListFilters.orderChoice === this.field) {
        this.$store.commit('setProjectListFilters', { orderChoice: `-${this.field}` })
      } else {
        this.$store.commit('setProjectListFilters', { orderChoice: this.field })
      }
    }
  },
  props: {
    field: String,
    title: String
  }
}
</script>
