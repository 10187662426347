<template>
  <div class="project-list">
    <Page title="Project list">
      <div class="flex justify-between">
        <div class="flex items-end">

          <div class="relative px-2">
            <div>
              <Button
                class="flex flex-row justify-center items-center w-full"
                aria-expanded="true"
                aria-haspopup="true"
                buttontype="neutral-border"
                id="menu-button"
                @blur="handleMenuBlur"
                @click="handleMenuToggle"
              >
              {{ this.projectFilters[selectedFilterIdx].name }}
              <Icon name="chevron-down"/>
              </Button>
            </div>
            <div
            :class="{ hidden : menuHidden }"
              class="origin-top-left absolute left-4 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              aria-labelledby="menu-button"
              aria-orientation="vertical"
              role="menu"
              tabindex="-1"
            >
              <div
                class="p-1"
                role="none"
                v-for="item in projectFilters"
                :key="item.name"
              >
                <a
                  class="text-neutral-700 block px-4 py-2 text-sm hover:bg-neutral-50"
                  :id="item.id"
                  href="#"
                  role="menuitem"
                  tabindex="-1"
                  @click="handleMenuSelect(item.id)"
                >
                  {{ item.name }}
                </a>
              </div>
            </div>
          </div>
          <Button
            v-if="this.max_projects !== 1"
              buttontype="border"
              @click="$router.push('/create-project')"
              class="flex flex-row items-center"
            >
              <span class="block flex-auto mr-3">
                Create new project
              </span>
              <Icon
                class="block flex-none h-6 w-6"
                name="plus"
              />
          </Button>
          <Button
            v-else
            buttontype="disabled-border"
            class="flex flex-row items-center"
            title="Pro only feature"
          >
            <span class="block flex-auto mr-3">Create new project</span>
            <Icon class="block flex-none h-6 w-6" name="plus" />
          </Button>
          <Input
          class="py-1"
          field="title"
          inputclass="input--default"
          placeholder="Search"
          type="text"
          v-model="searchTerm"
          @input="handleSearchInput(searchTerm)"
          />
        </div>
        <Button
          v-if="projects && projects.filter(p => p.selected === true).length > 0 && this.max_projects !== 1"
          class="self-end"
          buttontype="background"
          @click="handleArchive">
            Archive selected
        </Button>

        <Button
          v-else-if="projects && projects.filter(p => p.selected === true).length > 0"
          class="self-end"
          buttontype="disabled-border"
          title="Pro only feature"
        >
          Archive selected
        </Button>
      </div>
      <div v-if="projects && projects.length" class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-6 py-3 text-left font-medium">Select</th>
              <th class="px-6 py-3 text-left font-medium text-gray-500">Status</th>
              <th class="px-6 py-3 text-left font-medium text-gray-500"><OrderColumn title="Project name" field="title" /></th>
              <th class="px-6 py-3 text-left font-medium text-gray-500"><OrderColumn title="Date created" field="added_datetime"/></th>
              <th class="px-6 py-3 text-left font-medium text-gray-500">Client</th>
              <th class="px-6 py-3 text-left font-medium text-gray-500">Client budget</th>
              <th class="px-6 py-3 text-left font-medium text-gray-500">Estimated margin</th>
              <th class="px-6 py-3 text-left font-medium text-gray-500">Active?</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr  v-for="p in projects" :key="p.id">
              <td class="px-6 py-4 whitespace-nowrap">
                <input type="checkbox" v-model="p.selected">
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div v-if="p.rec_mode" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">Live</div>
                <div v-else class=" px-2 inline-flex text-xs font-semibold rounded-full bg-yellow-100 text-yellow-800">Est</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <router-link :to="`/project/${p.id}`">
                  <div :title="p.description" class="inline-flex align-middle">
                    <Icon name="info" />
                  </div>
                  {{ p.title }}
                </router-link>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">{{ formatDate(p.added_datetime) }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ p.client }}</td>
              <td class="px-6 py-4 whitespace-nowrap">{{ penceToPounds(p.client_budget_pence) }}</td>
              <td v-if="p.estimated_margin_pence" class="px-6 py-4 whitespace-nowrap">
                {{ penceToPounds(p.estimated_margin_pence) }} ({{ p.estimated_margin_pc }}%)
              </td>
              <td v-else class="px-6 py-4 whitespace-nowrap">{{ DEFAULT_STR }}</td>
              <td v-if="p.is_active" class="px-6 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-success-100 text-success-800">
                    Active
                </span>
              </td>
              <td v-else class="px-6 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-error-100 text-error-800">
                    Archived
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>There are no projects to display.</p>
      </div>
    </Page>
  </div>
</template>

<script>
import Button from '@/components/button.vue'
import Input from '@/components/input.vue'
import Icon from '@/components/icon.vue'
import OrderColumn from '@/components/orderColumn.vue'
import Page from '@/components/page.vue'
import { defaultIfNone, formatDate, penceToPounds, debounce } from '@/components/js/utils.js'
import { DEFAULT_STR } from '@/components/js/constants.js'
import { mapState } from 'vuex'

export default {
  name: 'ProjectList',
  components: {
    Button,
    Input,
    Icon,
    OrderColumn,
    Page
  },
  computed: {
    ...mapState({
      projects: state => state.projects.projects,
      count: state => state.projects.count,
      max_projects: state => state.user.organisation.max_projects
    }),
    projectFilters: function () {
      return [
        { id: 0, name: `Active projects (${this.count?.active})`, filter: 'is_active=True' },
        { id: 1, name: `Archived projects (${this.count?.inactive})`, filter: 'is_active=False' },
        { id: 2, name: `All projects (${this.count?.total})`, filter: '' }
      ]
    }
  },
  created () {
    this.$store.dispatch('getCount')
    this.$store.commit('setProjectListFilters', { filterChoice: 'is_active=True' })
    this.$store.dispatch('getOrganisation')
    this.$store.dispatch('getProjects', false)
    this.debouncedSearch = debounce(() => {
      this.$store.dispatch('getProjects', false)
    }, 300)
  },
  data () {
    return {
      DEFAULT_STR: DEFAULT_STR,
      searchTerm: '',
      selectedFilterIdx: 0,
      menuHidden: true
    }
  },
  methods: {
    defaultIfNone,
    formatDate,
    penceToPounds,
    handleArchive: function () {
      const selectedIds = []
      for (const project of this.projects.filter(p => p.selected === true)) {
        selectedIds.push(project.id)
      }
      this.$store.dispatch('patchArchiveProjects', { ids: selectedIds }).then(function () {
        this.$store.dispatch('getCount')
      }.bind(this))
    },
    handleMenuToggle: function () {
      this.menuHidden = !this.menuHidden
    },
    handleMenuSelect: function (itemId) {
      // id matches index in filter list
      this.selectedFilterIdx = itemId
      this.handleMenuToggle()
      this.$store.commit('setProjectListFilters', { filterChoice: this.projectFilters[itemId].filter })
      this.$store.dispatch('getProjects', false)
    },
    handleMenuBlur: function () {
      setTimeout(() => { this.menuHidden = true }, 500)
    },
    handleSearchInput: function (searchTerm) {
      this.$store.commit('setProjectListFilters', { searchTerm: searchTerm })
      this.debouncedSearch()
    }
  }
}
</script>
